import { create } from "zustand";

const useMediaPlayerStore = create(set => ({
  currentTimeStamp: null,
  setCurrentTimeStamp: currentTimeStamp => set({ currentTimeStamp }),
  timePlaying: {},
  setTimePlaying: val => set({ timePlaying: val })
}));


export default useMediaPlayerStore;
