/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import useTranscrpitionStore from "store/transcriptionStore.ts";
import { getCandidateAnswers } from "store/modules/jobs/selectors";
import useMediaPlayerStore from "store/mediaPlayerStore";
import CandidateTranscriptionItem from "./CandidateTranscriptionItem.tsx";

const Transcript = ({ currentQuestionAnswer, candidateAnswers }) => {
  const { transcriptions, searchString } = useTranscrpitionStore();
  const { timePlaying } = useMediaPlayerStore();
  const answerLength = candidateAnswers?.length;
  const currentTranscription = transcriptions?.find(a => a.key === currentQuestionAnswer?.key);

  // Add state for auto-scroll
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

  const scrollIndex = useMemo(() => currentTranscription?.sentences?.findIndex(a =>
    a.start_time <= timePlaying?.[currentQuestionAnswer?.key]?.time &&
    a.end_time >= timePlaying?.[currentQuestionAnswer?.key]?.time),
  [currentTranscription, timePlaying, currentQuestionAnswer]);

  function smoothScroll(element, target, duration) {
    if (!isAutoScrollEnabled) return; // Don't scroll if disabled

    const start = element.scrollTop;
    const maxScroll = (element.scrollHeight - element.clientHeight);
    const targetAdjusted = Math.min(target, maxScroll);
    const change = targetAdjusted - start + 120;
    const startTime = performance.now();
    let now; let elapsed; let
      time;

    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    function animateScroll() {
      now = performance.now();
      elapsed = (now - startTime) / 1000;
      time = (elapsed / duration);

      element.scrollTop = start + change * easeInOutQuad(time);

      if (time < 1) window.requestAnimationFrame(animateScroll);
    }

    animateScroll();
  }

  const transcriptContentRef = useRef(null);

  const scrollToAnswer = useCallback((index, forcedScroll = false) => {
    if (!isAutoScrollEnabled && !forcedScroll) return;

    const element = document.getElementById(`transcription-item-${index}`);
    const container = document.getElementById("candidate__sidebar-container");

    if (element && container) {
      const target = element?.offsetTop;
      smoothScroll(container, target, 0.5);
    }
  }, [isAutoScrollEnabled, smoothScroll]);

  // Effect for auto-scrolling
  useEffect(() => {
    if (scrollIndex !== -1 && isAutoScrollEnabled) {
      scrollToAnswer(scrollIndex);
    }
  }, [scrollIndex, scrollToAnswer, isAutoScrollEnabled]);

  // Effect to handle manual scroll detection
  useEffect(() => {
    const container = document.getElementById("candidate__sidebar-container");
    if (!container) return;

    const handleManualScroll = () => {
      if (isAutoScrollEnabled) {
        setIsAutoScrollEnabled(false);
      }
    };

    container.addEventListener("wheel", handleManualScroll, { passive: true });
    container.addEventListener("touchmove", handleManualScroll, { passive: true });

    return () => {
      container.removeEventListener("wheel", handleManualScroll);
      container.removeEventListener("touchmove", handleManualScroll);
    };
  }, [isAutoScrollEnabled]);

  if (searchString) {
    return (
      <div className="transcript-content">
        {
        candidateAnswers?.map((answer, index) => {
          const trans = transcriptions?.find(t => t.question_key === answer?.question?.key);

          if (!trans) return null;

          return (
            <div key={`answer-trans-${String(index)}`}>
              <div className="px-2">
                <div
                  className={`flex flex-row items-center justify-between w-full ${currentQuestionAnswer?.question?.key === trans?.question_key
                    ? "text-main-purple" : "text-main-link"} text-xs font-medium border-b mt-3 mb-3 pb-2`}
                  style={{
                    borderBottomColor: "#5A2AF1"
                  }}
                >
                  <div>
                    {`Q ${index + 1} of ${answerLength}`}
                  </div>
                  <div>
                    {trans.sentences?.length}
                  </div>
                </div>
              </div>
              {
                trans.sentences?.map((transcription, idx) => (
                  <CandidateTranscriptionItem
                    key={`transcript-sentence-item-${String(index)}`}
                    transcription={{ ...transcription, ...trans }}
                    answer={answer}
                    scrollToAnswer={scrollToAnswer}
                    idx={idx}
                  />
                ))
              }
            </div>
          );
        })
        }
      </div>
    );
  }

  return (
    <>
      <div className="transcript-content" ref={transcriptContentRef}>
        {currentTranscription?.sentences?.map((transcription, index) => (
          <CandidateTranscriptionItem
            key={transcription.id}
            transcription={transcription}
            answer={currentQuestionAnswer}
            idx={index}
            isHighlighted={index === scrollIndex}
            scrollToAnswer={() => {
              scrollToAnswer(index);
            }}
          />
        ))}

      </div>
      {
        !isAutoScrollEnabled && (
          <div
            className="absolute bg-main-purple rounded-lg px-1 py-1 text-white text-xs mx-auto z-50 left-1/2 transform -translate-x-1/2 cursor-pointer"
            style={{
              bottom: 50
            }}
            onClick={() => {
              setIsAutoScrollEnabled(true);
              scrollToAnswer(scrollIndex);
            }}
          >
            Back to current time
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  candidateAnswers: getCandidateAnswers(state)
});

export default connect(mapStateToProps)(Transcript);
