/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import { Close, SidebarCloseIcon } from "mixins/svgIcons";

import CandidateDownloadIcon from "assets/images/candidate/CandidateDownloadIcon";
import "./styles.scss";
import { BeatLoader } from "react-spinners";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import { store } from "store";
import { setSidebarOpen } from "store/modules/jobs/actions";
import { EMPTY, FAILED, NOT_AVAILABLE } from "configs/jobs/constants";
import { getToken } from "store/modules/auth/selectors";
import { withRouter } from "react-router-dom";
import CustomTooltip from "components/Common/CustomTooltip/CustomTooltip.tsx";
import WilloIntelligence from "assets/images/candidate/willo-intelligence.png";
import useTranscrpitionStore from "store/transcriptionStore.ts";
import { debounce } from "lodash";
import Spinner from "components/Common/Spinner/index";
import CandidateTranscription from "./CandidateTranscription";
import NoTranscriptionsHistory from "./NoTranscriptionsHistory.tsx";
import NoTranscriptionsResults from "./NoTranscriptionsResults";


const CandidateSidebarTranscriptions = ({
  t,
  isJobCandidateLoading,
  token,
  match: {
    params: { userId }
  },
  currentQuestionAnswer
}) => {
  const [isIconHovering, setIsIconHovering] = useState(false);

  const { transcriptions, setSearchString,
    searchString, searchTranscription, currentState, setCurrentState,
    downloadTranscription } = useTranscrpitionStore();

  const transcriptionStatus = transcriptions?.find(transcript =>
    transcript.key === currentQuestionAnswer?.key)?.transcript_text_generation_status;

  const isEmptyTranscriptions = transcriptions?.length === 0 ||
  ((([EMPTY, NOT_AVAILABLE, FAILED].includes(transcriptionStatus)) ||
  !transcriptionStatus) && !searchString);

  const debouncedSearching = useCallback(
    debounce(query => searchTranscription({
      search: query,
      id: userId,
      token
    }), 500),
    []
  );

  const handleSearchChange = useCallback(async value => {
    setSearchString(value);
    debouncedSearching(value);
    setCurrentState("loading");
  }, [debouncedSearching, setCurrentState, setSearchString]);

  return (
    <div className={`flex flex-col ${isEmptyTranscriptions ? "bg-black-5" : "bg-white"} flex-1`}>
      <div className="transcription__sidebar-wrapper flex flex-col flex-1">
        <div
          className="transcription__sidebar-container transcripts flex-1"
          style={{
            padding: "12px 11px",
            paddingBottom: 0
          }}
        >
          <div
            className="candidate__sidebar-comments__header flex w-full items-center justify-between"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px"
            }}
          >
            <div className="flex flex-row items-center gap-4">
              <CustomTooltip
                content={
                  <div className="font-normal">
                    <span className="text-main-purple font-poppins">{t("willoIntelligence.0")}</span>
                    {t("willoIntelligence.1")}
                  </div>
                }
              >
                <img
                  src={WilloIntelligence}
                  alt="Willo Intelligence"
                  className="w-5 h-5 cursor-help"
                />
              </CustomTooltip>
              <span className="text-base text-black-90">{t("transcriptions.title")}</span>
            </div>

            <SidebarCloseIcon
              style={{ pointerEvents: "all", cursor: "pointer" }}
              onClick={() => store.dispatch(setSidebarOpen(false))}
            />
          </div>
          <div className="transcription__header__search">
            <input
              id="transcript-search"
              className="form__search-input name-search__input n-font-small pr-10"
              placeholder="Search all responses..."
              onChange={e => handleSearchChange(e.target.value)}
              value={searchString}
              type="text"
            />
            {searchString && (
              <Close
                svgIconClass="integration__header__search-icon hover:cursor-pointer"
                fill={isIconHovering ? "#5A2AF1" : "#101933"}
                onClick={() => setSearchString("")}
                style={{ pointerEvents: "all", cursor: "pointer" }}
                onPointerEnter={() => setIsIconHovering(true)}
                onPointerLeave={() => setIsIconHovering(false)}
              />
            )}
          </div>
          {
            isJobCandidateLoading ? (
              <div className="flex flex-col justify-center items-center flex-1">
                <BeatLoader color="#5A2AF1" size={10} />
                <span className="loading-text">{t("button.loading")}</span>
              </div>
            ) :
              currentState === "loading" ? (
                <div className={`flex flex-col ${isEmptyTranscriptions ? "bg-black-5" : "bg-white"} flex-1`}>
                  <Spinner />
                </div>
              )
                :
                (
                  <>
                    {
                      searchString && isEmptyTranscriptions ? <NoTranscriptionsResults
                        retry={handleSearchChange}
                      /> :
                        isEmptyTranscriptions
                          ? <NoTranscriptionsHistory /> :
                          <CandidateTranscription />
                    }
                  </>
                )
          }
        </div>
      </div>

      <>
        <div className="flex flex-col sticky bottom-0">
          <div className="h-5 bg-gradient-to-t from-white to-transparent" />
          <div
            className="p-3 flex flex-row items-center justify-center gap-2 text-xs text-main-link font-semibold w-full bg-white cursor-pointer hover:text-main-purple focus:text-main-link focus-within:text-main-link outline-none"
            onClick={() => {
              downloadTranscription(userId, token);
            }}
          >
            {
              searchString ? null : (
                <>
                  {t("sidebarWidget.downloadTranscription")}
                  {" "}
                  <CandidateDownloadIcon />
                </>
              )
            }

          </div>
        </div>
      </>
    </div>
  );
};


const mapStateToProps = state => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  token: getToken(state),
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default withRouter(
  connect(mapStateToProps)(withTranslation()(CandidateSidebarTranscriptions))
);
