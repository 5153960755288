/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { isIOS, isMobile } from "react-device-detect";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js";

import Message from "components/Common/Message";
import {
  canPlayExtension,
  formatStringtoDuration,
  customTimeFormat
} from "mixins/helperVideoRecording";

import { connect } from "react-redux";
import { getCandidateVolume } from "store/modules/сandidates/selectors";
import { updateVideoPreference } from "store/modules/сandidates/actions";
import { store } from "store";
import getValidVolume from "utils/number";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { removeDuplicates } from "mixins/helpers";
import { updateProfileInfo } from "store/modules/profile/actions";
import { getProfileInfo } from "store/modules/profile/selectors";
import { isCandidateRoute } from "mixins/helperCandidate";
import * as Sentry from "@sentry/browser";
import "./styles.scss";
import { Replay } from "components/Video/Player/svgIcons";
import useWindowResize from "hooks/useWindowResize.tsx";
import useMediaPlayerStore from "store/mediaPlayerStore";
import { isFinite } from "lodash";

const overrideNative = true;

const CandidateAnswerMediaPlayer = ({
  className,
  sources,
  downloadSource,
  withDownload,
  questionKey,
  questionNumber,
  style,
  onReady,
  withError,
  mediaExtension,
  onlyAudio,
  isCandidate,
  settings,
  setSettings,
  currentTime,
  setCurrentTime,
  withNext,
  withPrevious,
  handleNextQuestion,
  handlePreviousQuestion,
  volume,
  currentQuestionAnswer: {
    is_reloaded: isReloaded = false,
    is_muted: isMuted = false
  } = {},
  videoErrors,
  setVideoErrors,
  handleGoToScorecard,
  showScorecardButton,
  playbackSpeed,
  setIsPlaying,
  showPreview,
  setShowPreview,
  isPlaying,
  thumbnailRemoteLink,
  ...rest
}) => {
  const { setTimePlaying, currentTimeStamp } = useMediaPlayerStore();
  const [isVisibleReplay, setVisibleReplay] = useState(false);
  const [animated, setAnimated] = useState(true);
  const videoId = `video_js_${questionKey || questionNumber}`;
  const playerRef = useRef(null);
  const animationRef = useRef(null);
  const videoRef = useRef();
  const storeVolume = getValidVolume(volume);
  const { t } = useTranslation();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isVideoPortrait, setIsVideoPortrait] = useState(!!onlyAudio);
  const isPreviousClickedRef = useRef(false);

  const { isMobileAndTabletScreen } = useWindowResize();

  useEffect(() => {
    const animation = animationRef.current;
    if (animation) animation.onanimationend = handleNextQuestion;
    return () => {
      if (animation) animation.onanimationend = null;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRef.current]);

  const canPlay = useMemo(() => {
    if (!withError || !mediaExtension) return "supported";
    return canPlayExtension(mediaExtension.toLowerCase());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaExtension]);

  const handleReplay = () => {
    if (playerRef.current) {
      setTimePlaying({});
      setAnimated(true);
      setShowPreview(true);

      playerRef.current.currentTime(0);
      playerRef.current.play();
    }
  };

  const onVolumeChange = () => {
    const video = document.querySelector("video");

    store.dispatch(
      updateVideoPreference(
        video.muted ? 0 : video.volume === 0 ? 1 : video.volume
      )
    );
  };

  const onRateChange = useCallback(() => {
    const video = document.querySelector("video");

    store.dispatch(
      updateProfileInfo({
        preferred_playback_speed: video.playbackRate
      })
    );
  }, []);

  useEffect(() => {
    const video = document.querySelector("video");

    video.muted = isIOS ? true : storeVolume === 0;
    video.volume = storeVolume > 1 ? 1 : parseFloat(storeVolume);

    video.addEventListener("volumechange", onVolumeChange);

    return () => {
      video.removeEventListener("volumechange", onVolumeChange);
    };
  }, [storeVolume]);

  useEffect(() => {
    const video = document.querySelector("video");
    video.addEventListener("ratechange", onRateChange);

    return () => {
      video.removeEventListener("ratechange", onRateChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackSpeed]);

  useEffect(() => {
    const video = document.querySelector("video");
    if (!video || !currentTimeStamp || !hasLoaded) return;

    video.currentTime = currentTimeStamp;
    video.play();
  }, [currentTimeStamp, hasLoaded]);

  const isCurrentQuestionError = useMemo(() => {
    const currentVideoErrors = videoErrors?.filter(
      item => typeof item?.error !== "undefined"
    );

    if (currentVideoErrors?.length <= 0) return false;

    const hasError = currentVideoErrors?.find(
      item => item?.questionId === questionNumber
    );

    return {
      hasError: hasError?.error !== "" && typeof hasError?.error !== "undefined",
      message: hasError?.error,
      isMediaUploadInterrupted: hasError?.isMediaUploadInterrupted
    };
  }, [videoErrors, questionNumber]);

  const getMediaHttpStatus = useCallback(async vidPlayer => {
    try {
      // for video answers, check if mp4 works fine, then load it to the player
      setHasLoaded(false);
      let currentError = `${vidPlayer?.error()?.message || ""} ${t("errors.pleaseRefresh")}`;
      let isMediaUploadInterrupted = false;

      if (sources?.[0]?.src?.includes("null#t=")) {
        return setVideoErrors(prevErrors =>
          removeDuplicates(
            [
              ...(prevErrors ?? []),
              {
                questionId: questionNumber,
                error: `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`,
                isMediaUploadInterrupted: true
              }
            ],
            "questionId"
          ));
      }

      const response = await fetch(sources?.[0].src);

      if ([404, 403].includes(response?.status)) {
        currentError = `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`;
        isMediaUploadInterrupted = true;
      }

      if ([200, 201, 206].includes(response?.status)) {
        setHasLoaded(true);
        return setVideoErrors(prevErrors =>
          removeDuplicates(
            [
              ...(prevErrors ?? []),
              {
                questionId: questionNumber,
                error: currentError,
                isMediaUploadInterrupted: false,
                contactSupport: true
              }
            ],
            "questionId"
          ));
      }

      if (typeof setVideoErrors === "function" && ![200, 201, 206]?.includes(response?.status)) {
        return setVideoErrors(prevErrors =>
          removeDuplicates(
            [
              ...(prevErrors ?? []),
              {
                questionId: questionNumber,
                error: String(currentError || ""),
                isMediaUploadInterrupted,
                contactSupport: ![404, 403].includes(response?.status) && !isMediaUploadInterrupted
              }
            ],
            "questionId"
          ));
      }
    } catch (error) {
      console.log({ error });
      Sentry.captureException(error);

      setVideoErrors(prevErrors =>
        removeDuplicates(
          [
            ...(prevErrors ?? []),
            {
              questionId: questionNumber,
              error: "Unable to load the media. Please refresh or contact support.",
              isMediaUploadInterrupted: false,
              contactSupport: false
            }
          ],
          "questionId"
        ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionNumber, sources]);

  const handlePrevious = useCallback(() => {
    if ((isPreviousClickedRef.current && questionNumber > 1)) {
      handlePreviousQuestion();
    } else {
      isPreviousClickedRef.current = true;
      playerRef.current.currentTime(0);
      playerRef.current.play();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousClickedRef, questionNumber]);

  const insertAfter = (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  };

  const initializeControls = useCallback(player => {
    if (withNext) {
      const nextButton = document.createElement("button");
      nextButton.className = "vjs-next-button";
      nextButton.innerHTML = `
        <span class="videojs-next-icon"></span>
      `;
      nextButton.addEventListener("click", handleNextQuestion);

      const playProgress = player.controlBar.el().querySelector(".vjs-play-control");
      if (playProgress) insertAfter(nextButton, playProgress);
    }

    if (withPrevious) {
      const previousButton = document.createElement("button");
      previousButton.className = "vjs-previous-button";
      previousButton.innerHTML = `
        <span class="videojs-previous-icon"></span>
      `;

      const playProgress = player.controlBar.el().querySelector(".vjs-play-control");
      if (playProgress) player.controlBar.el().insertBefore(previousButton, playProgress);
    }

    const timeDisplay = document.createElement("div");
    timeDisplay.className = "vjs-time-display";

    timeDisplay.innerHTML = `
      <span>0:00</span>
      ${
        player.duration() === Infinity
          ? ""
          : `
          <span>/</span>
          <span>${formatStringtoDuration(player.duration())}</span>
        `
      }
    `;

    const filler = document.createElement("div");
    filler.className = "vjs-filler";

    const volumeControl = player.controlBar.el().querySelector(".vjs-volume-panel");

    if (volumeControl) insertAfter(timeDisplay, volumeControl);
    if (timeDisplay) insertAfter(filler, timeDisplay);

    const controlBar = player.controlBar.el();
    const controlBarWrapper = document.createElement("div");
    controlBarWrapper.className = "vjs-control-bar-wrapper";
    controlBar.parentNode.insertBefore(controlBarWrapper, controlBar);
    controlBarWrapper.appendChild(controlBar);
  }, []);

  useEffect(() => {
    let isMounted = true;
    let player;

    if (window.videojs && isMounted) {
      isMounted = false;

      window.videojs.options.vhs.overrideNative = overrideNative;
      window.videojs.options.html5.vhs = {
        overrideNative
      };
      window.videojs.options.html5.nativeAudioTracks = !overrideNative;
      window.videojs.options.html5.nativeVideoTracks = !overrideNative;
      window.videojs.options.html5.nativeTextTracks = !overrideNative;

      player = window.videojs(
        videoId,
        {
          plugins: {
            ...(onlyAudio
              ? {
                wavesurfer: {
                  cursorColor: "#5A2AF1",
                  progressColor: "#5A2AF1",
                  waveColor: "#676767",
                  backgroundColor: "#000",
                  interact: false,
                  barGap: 3,
                  barHeight: 6,
                  barMinHeight: 0.5,
                  barRadius: 0,
                  barWidth: 3,
                  cursorWidth: 0,
                  autoCenter: true,
                  hideScrollbar: true,
                  responsive: true,
                  height: 200,
                  backend: "MediaElement",
                  plugins: [
                    CursorPlugin.create({
                      showTime: true,
                      opacity: 1,
                      customShowTimeStyle: {
                        "background-color": "#000",
                        color: "#fff",
                        padding: "2px",
                        "font-size": "10px"
                      },
                      formatTimeCallback: customTimeFormat
                    })
                  ]
                }
              }
              : null)
          }
        },
        () => {
          window.videojs.setFormatTime(customTimeFormat);

          player.on("loadeddata", () => {
            if (setSettings) {
              player.volume(settings.volume);
              player.playbackRate(playbackSpeed);
            }

            if (!isIOS) initializeControls(player);

            if (player.duration() === Infinity) {
              player.one("timeupdate", () => {
                player.currentTime(0);

                if (setCurrentTime) {
                  setCurrentTime({
                    time: player.currentTime(),
                    duration: player.duration(),
                    remaining: (player.duration() - player.currentTime())
                  });
                }
              });
            } else if (setCurrentTime) {
              const safeCurrentTime = isFinite(currentTime) && currentTime >= 0 ? currentTime : 0;
              const safeDuration = isFinite(player.duration()) ? player.duration() : 0;

              if (safeCurrentTime <= safeDuration) {
                player.currentTime(safeCurrentTime === safeDuration ? 0 : safeCurrentTime);
              }
            }
          });

          if (isIOS) {
            player.one("loadedmetadata", () => {
              initializeControls(player);

              player.play();
              player.pause();
              player.currentTime(0.0001);

              const video = document.querySelector("video");
              video.muted = storeVolume === 0;
              video.volume = storeVolume > 1 ? 1 : parseFloat(storeVolume);

              setTimeout(() => {
                setHasLoaded(true);
              }, 2000);
            });
          }

          if (onlyAudio) {
            if (player.autoplay()) player.autoplay("any");
            player.src(sources?.[0]);
          }

          const handleUpdate = () => {
            if (setSettings) {
              setSettings({
                volume: player.volume(),
                playbackRate: player.playbackRate()
              });
            }

            const playbackRatesMenu = player.controlBar.getChild("playbackRateMenuButton");

            if (playbackRatesMenu) {
              playbackRatesMenu.update();
            }
          };

          player.on("volumechange", handleUpdate);
          player.on("ratechange", handleUpdate);

          player.on("play", () => {
            setIsPlaying(true);
            if (withNext) {
              setVisibleReplay(false);
            }
          });
          player.on("pause", () => {
            setIsPlaying(false);
          });
          player.on("ended", () => {
            if (withNext) {
              setVisibleReplay(true);
            }
          });

          player.on("canplay", () => {
            if (sources?.[0]?.type?.includes("application/x-mpegURL") ||
              sources?.[0]?.type?.includes("audio/mp3")) setHasLoaded(true);
          });

          player.on("canplaythrough", () => {
            if (sources?.[0]?.type?.includes("video/mp4")) setHasLoaded(true);
          });

          player.on("timeupdate", () => {
            const timeDisplay = player.controlBar.el().querySelector(".vjs-time-display");

            if (timeDisplay) {
              timeDisplay.innerHTML = `
                <span>${formatStringtoDuration(player.currentTime(), true)}</span>
                ${
                  player.duration() === Infinity
                    ? ""
                    : `
                    <span>/</span>
                    <span>${formatStringtoDuration(player.duration())}</span>
                  `
                }
              `;
            }

            setCurrentTime({
              time: isFinite(player.currentTime()) ? player.currentTime() : 0,
              duration: isFinite(player.duration()) ? player.duration() : 0,
              remaining: isFinite(player.duration()) && isFinite(player.currentTime())
                ? (player.duration() - player.currentTime())
                : 0
            });
          });
        }
      );

      player.on("loadedmetadata", () => {
        if (typeof onReady === "function") onReady();

        if (!onlyAudio) {
          const isPortrait = player.videoHeight() > player.videoWidth();
          setIsVideoPortrait(isPortrait);
        }
      });

      if (isMobile) {
        player.controlBar.volumePanel.volumeControl.volumeBar.hide();
        player.on("touchstart", function (e) {
          if (e.target.nodeName === "VIDEO") {
            if (player.paused()) {
              this.play();
            } else {
              this.pause();
            }
          }
        });
      }

      player.on("error", () => {
        try {
          const currentError = player.error();

          if (!isCurrentQuestionError?.hasError && !isReloaded) {
            if (currentError?.code === 4) {
              setHasLoaded(false);
              return getMediaHttpStatus(player);
            }

            const errorMessage = isReloaded
              ? `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`
              : `${currentError?.message || ""} ${t("errors.pleaseRefresh")}`;

            return setVideoErrors(prevErrors =>
              removeDuplicates(
                [
                  ...(prevErrors ?? []),
                  {
                    questionId: questionNumber,
                    error: errorMessage,
                    contactSupport: !isReloaded
                  }
                ],
                "questionId"
              ));
          }
        } catch (errorMess) {
          console.log({ playerErrors: errorMess });
        } finally {
          setHasLoaded(true);
        }
      });

      playerRef.current = player;

      return () => {
        isMounted = true;
        if (player) {
          player.dispose();
          setCurrentTime({});
        }
      };
    }
  }, []);

  useEffect(() => {
    const previousBtn = document.querySelector(".vjs-previous-button");

    if (previousBtn && hasLoaded) {
      previousBtn.addEventListener("click", handlePrevious);
    }

    return () => {
      if (previousBtn) {
        previousBtn.removeEventListener("click", handlePrevious);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  const message = {
    subject: t("errors.errorFallback.email.subject", { error: "Error: Failed to load media" }),
    text: t("errors.errorFallback.email.body")
  };

  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", message);
    }
  };

  const shouldShowErrorMessage = isReloaded || (
    isCurrentQuestionError?.hasError &&
    (isCurrentQuestionError?.isMediaUploadInterrupted || hasLoaded)
  );

  return (
    <div
      className={`videojs__player-container v2 ${withNext &&
        isVisibleReplay &&
        "ended"}`}
    >
      {isCandidate || onlyAudio || canPlay ? null : (
        <Message error message={withError} />
      )}
      <div data-vjs-player type="button" style={style}>
        {isMuted &&
          process.env.REACT_APP_DETECT_MUTE !== "false" && (
          <div className="no-audio-wrapper">
            <div className="no-audio-text" />
            {t("candidate.videoCreate.noAudio")}
          </div>
        )}

        {shouldShowErrorMessage && (
          <div className="video-custom-error-wrapper">
            <span className="video-custom-error">
              {
                videoErrors?.find(item => item?.questionId === questionNumber)
                  ?.error || t("errors.errorModal")
              }

              {
                videoErrors?.find(item => item?.questionId === questionNumber)?.contactSupport && (
                  <button
                    className="button__without-styles contact-support"
                    type="button"
                    onClick={openBeacon}
                    aria-label="Contact support"
                  >
                    {t("link.needSupport")}
                  </button>
                )
              }
            </span>
          </div>
        )}

        {!hasLoaded && (!isCurrentQuestionError?.hasError || !isCurrentQuestionError) && (
          <div
            className="processing-wrapper"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              background: "black",
              zIndex: 5,
              top: 0
            }}
          >
            <BeatLoader color="#BBC2C9" size={10} margin={1} loading />
          </div>
        )}
        <video
          id={videoId}
          className={`video-js video__preview-item-player ${className} ${(isMobile || isMobileAndTabletScreen) &&
            "video-js-mobile"} ${onlyAudio ? "audio-js" : ""} ${
            isCandidate ? (onlyAudio ? "candidate-audio" : "candidate") : ""
          } ${onlyAudio ? "" : isVideoPortrait ? "portrait" : "landscape"} ${thumbnailRemoteLink ? "has-poster" : "no-poster"}`}
          controls
          preload="auto"
          width="640"
          height="264"
          data-setup={JSON.stringify(rest)}
          ref={videoRef}
          style={hasLoaded && !isVideoPortrait ? { backgroundColor: "transparent" } : {}}
          playsInline
        >
          {onlyAudio
            ? null
            : sources?.map(source => (
              <source key={source.src} src={source.src} type={source.type} />
            ))}
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            {" "}
            <a
              href="http://videojs.com/html5-video-support/"
              target="_blank"
              rel="noopener noreferrer"
            >
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
      <div className="videojs__replay-container v2">
        {
          !showPreview ? (
            <button
              className="button__without-styles videojs__replay-button v2"
              onClick={handleReplay}
              type="button"
              aria-label="Replay"
            >
              <Replay />
            </button>
          ) : null
        }
        {
          showScorecardButton &&
          <button
            className={`button__without-styles videojs__replay-next ${animated &&
            "animated"}`}
            onClick={() => handleGoToScorecard()}
            type="button"
            ref={animationRef}
            aria-label="Go to scorecard"
          >
            <p className="n-font-medium n-font-medium-weight n-grey-60">
              {t("scorecard.completeScorecard")}
            </p>
          </button>
        }
      </div>
    </div>
  );
};

CandidateAnswerMediaPlayer.defaultProps = {
  style: null,
  onReady: null,
  settings: {},
  setSettings: null,
  currentTime: 0,
  setCurrentTime: null,
  withNext: false,
  withPrevious: false,
  sources: undefined,
  downloadSource: undefined,
  videoErrors: undefined,
  setVideoErrors: undefined,
  withError: false,
  handleNextQuestion: () => { },
  handlePreviousQuestion: () => { },
  showPreview: false,
  setShowPreview: () => { },
  isPlaying: false,
  thumbnailRemoteLink: undefined
};

CandidateAnswerMediaPlayer.propTypes = {
  className: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ),
  downloadSource: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ),
  withDownload: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  questionKey: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  onReady: PropTypes.func,
  style: PropTypes.node,
  withError: PropTypes.string,
  mediaExtension: PropTypes.string.isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  isCandidate: PropTypes.bool.isRequired,
  withNext: PropTypes.bool,
  withPrevious: PropTypes.bool,
  settings: PropTypes.shape({
    volume: PropTypes.number,
    playbackRate: PropTypes.number
  }),
  setSettings: PropTypes.func,
  currentTime: PropTypes.number,
  setCurrentTime: PropTypes.func,
  currentQuestionAnswer: PropTypes.shape({
    is_muted: PropTypes.bool,
    is_reloaded: PropTypes.bool,
    remote_link: PropTypes.string
  }).isRequired,
  videoErrors: PropTypes.arrayOf(PropTypes.shape({})),
  setVideoErrors: PropTypes.func,
  showScorecardButton: PropTypes.bool.isRequired,
  handleGoToScorecard: PropTypes.func.isRequired,
  playbackSpeed: PropTypes.number.isRequired,
  handleNextQuestion: PropTypes.func,
  handlePreviousQuestion: PropTypes.func,
  setIsPlaying: PropTypes.func.isRequired,

  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func,
  isPlaying: PropTypes.bool,
  thumbnailRemoteLink: PropTypes.string
};

const mapStateToProps = state => ({
  volume: getCandidateVolume(state),
  playbackSpeed: isCandidateRoute ? 1 : getProfileInfo(state)?.preferred_playback_speed ?? 1,
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(CandidateAnswerMediaPlayer);
